<template>
  <div
    class='relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden'
  >
    <dt>
      <div class='absolute bg-blue-500 rounded-md p-3'>
        <IconCheck class='h-6 w-6 text-white' />
      </div>
      <p class='ml-16 text-sm font-medium text-gray-700'>
        {{ label }}
      </p>
    </dt>
    <dd class='ml-16 flex items-baseline'>
      <p class='text-2xl font-semibold text-gray-900'>
        {{ value }}
      </p>
    </dd>
  </div>
</template>
<script>
export default {
  name: 'statistics-key-data-card',
  props: ['label', 'value']
}
</script>
