<template>
    <div
        :id="item.id"
        :aria-labelledby="`${item.id}-tab`"
        class="tab-pane fade"
        role="tabpanel"
    >
        <h3
            id="applicant-information-title"
            class="text-lg leading-6 font-bold text-blue-500 py-6"
        >
            {{ item.name }}
        </h3>
        <div v-if="form.racis && form.racis.length > 0">
            <div class="d-flex flex-wrap">
                <div v-if="responsible">
                    <h4>Responsable :</h4>
                    <div class="d-flex flex-wrap demo demo-h-spacing mt-1 mb-3">
                        <UserCompactCard
                            v-for="user in responsible"
                            :key="user.id"
                            :user="user"
                        ></UserCompactCard>
                    </div>
                </div>

                <div v-if="accountable">
                    <h4>Approbateur :</h4>
                    <div class="d-flex flex-wrap demo demo-h-spacing mt-1 mb-3">
                        <UserCompactCard
                            :user="accountable"
                            v-for="user in accountable"
                            :key="user.id"
                        ></UserCompactCard>
                    </div>
                </div>
            </div>

            <div>
                <div class="mt-5" v-if="consulted && consulted.length > 0">
                    <h4>Contributeurs :</h4>
                    <div class="d-flex flex-wrap demo demo-h-spacing mt-1 mb-3">
                        <UserCompactCard
                            v-for="user in consulted"
                            :key="user.id"
                            :user="user"
                        ></UserCompactCard>
                    </div>
                </div>

                <div class="mt-5" v-if="informed && informed.length > 0">
                    <h4>Informés :</h4>
                    <div class="d-flex flex-wrap demo demo-h-spacing mt-1 mb-3">
                        <UserCompactCard
                            v-for="user in informed"
                            :key="user.id"
                            :user="user"
                        ></UserCompactCard>
                    </div>
                </div>
            </div>
        </div>
        <div v-else><p>Aucun opérateur pour le moment</p></div>
    </div>
</template>

<script>
import FormMixin from './FormMixin'
import { mapState } from 'vuex'
import UserCompactCard from '../../User/UserCompactCard'

export default {
    name: 'FormRaci',
    components: { UserCompactCard },
    mixins: [FormMixin],
    props: ['item'],
    data() {
        return {
            responsible: [],
            accountable: null,
            consulted: [],
            informed: []
        }
    },
    computed: {
        ...mapState('form', {
            form: (state) => state.form
        })
    },
    created() {
        console.log(this.form.racis)
        this.form.racis.map((user) => {
            if (user.is_responsible) {
                this.responsible.push(user)
            }

            if (user.is_accountable) {
                this.accountable = user
            }

            if (user.is_consulted) {
                this.consulted.push(user)
            }

            if (user.is_informed) {
                this.informed.push(user)
            }
        })
    },
    methods: {}
}
</script>
