<template>
    <bs-modal type="modal-dialog-centered" :id="id">
        <div class="p-4">
            <div class="text-xl">
                {{ $t('delete.are_you_sure') }}
                {{ $t('application.designation') }}
                <span class="font-bold">{{ application.name }}</span>
                ?
            </div>
        </div>
        <template #footer>
            <div class="flex inline-flex justify-center items-center space-x-5">
                <button
                    @click.prevent="cancel"
                    type="button"
                    class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    {{ $t('template.delete.cancel') }}
                </button>
                <button
                    @click.prevent="deleteOneApplication"
                    type="button"
                    class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                    {{ $t('template.delete.delete') }}
                </button>
            </div>
        </template>
    </bs-modal>
</template>
<script>
import BsModal from '@/components/UI/BsModal'
import ModalMixin from '@/components/UI/ModalMixin'
import { mapActions } from 'vuex'
export default {
    mixins: [ModalMixin],
    props: ['application'],
    components: {
        BsModal
    },
    data() {
        return {
            id: 'deleteApp'
        }
    },
    methods: {
        ...mapActions('application', ['deleteApplication']),
        trigger: function () {
            this.modal.show()
        },
        cancel: function () {
            this.modal.hide()
        },
        deleteOneApplication() {
            const self = this
            this.deleteApplication({
                formId: this.application.form_id,
                id: this.application.id
            })
                .then(() => {
                    this.cancel()
                    this.$store.dispatch('toast', {
                        type: 'success',
                        title: this.$t('success.title')
                    })
                    this.$router.back()
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    this.$store.dispatch('dispatchError', error)
                })
        }
    },
    mounted() {
        this.create(this.id, {
            keyboard: false
        })
    },
    computed: {}
}
</script>
