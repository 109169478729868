<template>
    <div :class="props.row.deleted_at ? 'text-decoration-line' : ''">
        {{ data }}
    </div>
</template>
<script>
export default {
    props: ['props', 'data']
}
</script>
