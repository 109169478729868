<template>
    <main v-if="isLoaded" id="detail-ticket" class="page-content" role="main">
        <form-menu></form-menu>
    </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormMenu from '@/components/Model/Form/FormComponents/FormMenu'

export default {
    name: 'Form',
    data() {
        return {
            isLoaded: false
        }
    },
    components: {
        FormMenu
    },
    created() {
        var id = this.$route.params.id
        this.getForm(id).then(() => {
            this.isLoaded = true
        })
    },
    computed: {
        ...mapState('form', {
            form: (state) => state.form
        })
    },
    methods: {
        ...mapActions('form', ['getForm'])
    }
}
</script>

<style scoped></style>
