<template>
    <div class="is-flex is-flex-direction-column" style="height: 100%">
        <div>
            <ul class="py-4">
                <li>
                    <h1 class="text-2xl font-bold text-blue-500">
                        {{ form.name }}
                    </h1>
                </li>
                <li>
                    <h2 v-html="form.description" class="text-xl text-gray-500">
                        {{ form.description }}
                    </h2>
                </li>
            </ul>
            <div id="pills-tab" class="nav flex nav-pills" role="tablist">
                <a
                    v-for="(item, index) in menu"
                    :id="`${item.id}-tab`"
                    :key="item.id"
                    :aria-controls="item.id"
                    :class="{ active: index === 0 }"
                    :href="`#${item.id}`"
                    class="inline-flex items-center px-3 py-2 mr-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    data-toggle="pill"
                    role="tab"
                >
                    <i class="text-white" :class="item.icon"></i>
                    <span class="hidden-sm-down ml-1">{{ item.name }}</span>
                </a>
            </div>
        </div>

        <div
            id="pillsContent"
            class="tab-content is-flex is-flex-direction-column is-justify-content-center"
            style="height: 100%"
        >
            <component
                :is="item.id"
                v-for="item in menu"
                :key="item.id"
                :item="item"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import FormInformation from '@/components/Model/Form/FormComponents/FormInformation'
import FormRaci from '@/components/Model/Form/FormComponents/FormRaci'
import FormDeployments from '@/components/Model/Form/FormComponents/FormDeployments'

export default {
    name: 'FormMenu',
    components: {
        informations: FormInformation,
        raci: FormRaci,
        deployments: FormDeployments
    },
    data: function () {
        return {
            menu: [
                {
                    id: 'informations',
                    name: this.$t('forms.menu.characteristics'),
                    icon: 'fal fa-poll-h'
                },
                {
                    id: 'raci',
                    name: this.$t('forms.menu.raci'),
                    icon: 'fal fa-users'
                },
                {
                    id: 'deployments',
                    name: this.$t('forms.menu.deployments'),
                    icon: 'fal fa-code-branch'
                }
            ]
        }
    },
    computed: {
        ...mapState('form', {
            form: (state) => state.form
        })
    },
    methods: {
        ...mapActions('form', ['getForm'])
    }
}
</script>

<style scoped></style>
