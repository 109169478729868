<template>
    <div v-if="user"
        class="rounded-pill bg-white shadow-sm p-2 border-faded mr-3 d-flex flex-row align-items-center justify-content-center flex-shrink-1 mt-2">
        <img :src="`https://www.gravatar.com/avatar/${user.id}-${user.email}`" :alt="user.name"
             class="img-thumbnail img-responsive rounded-circle"
             style="width:5rem; height: 5rem;">
        <div class="ml-2 mr-3">
            <h5 class="m-0">
                {{ user.name }}
                <small class="m-0 fw-300">
                    {{ user.job_title }}
                </small>
            </h5>
            <a class="text-info fs-sm" href="#" @click.prevent="">{{ user.email }}</a> -
            <a :href="`mailto:${user.email}`" :title="`Contact ${user.name}`" class="text-info fs-sm"><i
                class="fal fa-envelope"></i></a>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserCompactCard",
    props: ['user']
}
</script>
