<template>
    <div>
        <b-table
            :data="applications.data"
            :debounce-search="200"
            :default-sort="['id', 'desc']"
            :loading="loading"
            :mobile-cards="true"
            :per-page="applications.per_page"
            :striped="true"
            :total="applications.total"
            backend-filtering
            backend-pagination
            backend-sorting
            paginated
            @sort="onSort"
            @page-change="onPageChange"
            @filters-change="onFilterChange"
            v-if="applications && applications.data.length > 0"
            class="table-striped is-fullwidth"
        >
            <b-table-column
                field="id"
                :label="$t('forms.applications.reference')"
                numeric
                sortable
                centered
                v-slot="props"
            >
                <StrokeData :data="props.row.id" :props="props" />
            </b-table-column>

            <b-table-column
                field="name"
                :label="$t('forms.applications.name')"
                numeric
                sortable
                centered
                v-slot="props"
            >
                <StrokeData :data="props.row.name" :props="props" />
            </b-table-column>

            <b-table-column
                field="source"
                :label="$t('forms.applications.source')"
                numeric
                sortable
                centered
                v-slot="props"
            >
                <StrokeData :data="props.row.source" :props="props" />
            </b-table-column>

            <b-table-column
                field="active"
                :label="$t('forms.applications.active.label')"
                numeric
                sortable
                centered
                v-slot="props"
            >
                <StrokeData
                    :data="$t(`forms.applications.active.${props.row.active}`)"
                    :props="props"
                />
            </b-table-column>

            <b-table-column
                field="lang"
                :label="$t('forms.applications.lang.label')"
                numeric
                centered
                v-slot="props"
            >
                <StrokeData
                    :data="$t(`forms.applications.lang.${props.row.lang}`)"
                    :props="props"
                />
            </b-table-column>

            <b-table-column
                field="client_url"
                :label="$t('forms.applications.client_url')"
                numeric
                centered
                v-slot="props"
            >
                <StrokeData :data="props.row.client_url" :props="props" />
            </b-table-column>

            <b-table-column
                field="created_by"
                :label="$t('forms.applications.created_by')"
                numeric
                sortable
                centered
                v-slot="props"
            >
                <StrokeData :data="props.row.created_by.name" :props="props" />
            </b-table-column>

            <b-table-column
                field="created_at"
                :label="$t('forms.applications.created_at')"
                numeric
                sortable
                centered
                v-slot="props"
            >
                <StrokeData
                    :data="moment(props.row.created_at).format('L')"
                    :props="props"
                />
            </b-table-column>

            <b-table-column
                field=""
                :label="$t('forms.applications.actions')"
                numeric
                sortable
                centered
                v-slot="props"
            >
                <div
                    class="is-flex is-justify-content-center"
                    v-if="props.row.deleted_at == null"
                >
                    <router-link
                        :to="`/forms/${form.id}/app/${props.row.id}/edit`"
                        class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <IconModif class="h-4 w-4" />
                    </router-link>
                    <a
                        class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        href="#"
                        @click.prevent="downloadFile(props.row.id)"
                    >
                        <IconDownload class="h-4 w-4" />
                    </a>
                    <button
                        @click.prevent="deleteApp(props.row)"
                        class="inline-flex items-center px-3 py-2 ml-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <IconTrash class="h-4 w-4" />
                    </button>
                </div>
            </b-table-column>
            <DeleteApplication :application="appToDelete" ref="deleteApp" />
        </b-table>
        <div v-else>
            <p>{{ $t('forms.applications.no_deploy') }}</p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import StrokeData from '../../UI/StrokeData.vue'
import DeleteApplication from './DeleteApplication.vue'

export default {
    name: 'TabApplication',
    props: ['form'],
    components: {
        StrokeData,
        DeleteApplication
    },
    data: function () {
        return {
            isSearchable: false,
            appToDelete: {},
            search_field: '',
            loading: false
        }
    },
    created() {
        this.loadAsyncData()
    },
    computed: {
        ...mapState('application', {
            applications: (state) => state.applications,
            config: (state) => state.applicationsConfig
        })
    },
    methods: {
        ...mapActions('application', [
            'getApplications',
            'download',
            'deleteApplication'
        ]),
        loadAsyncData() {
            this.loading = true
            this.getData()
        },
        getData() {
            this.getApplications(this.form.id)
                .then(() => {
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                    this.$store.dispatch('dispatchError', error)
                })
        },
        onSort(field, order) {
            this.config.sort = field
            this.config.sortDirection = order

            this.loadAsyncData()
        },
        onFilterChange(filter) {
            var filters = {}
            Object.keys(filter).forEach((element) => {
                filters[element] = filter[element]
            })
            this.config.filters = filters

            this.loadAsyncData()
        },
        onPageChange(page) {
            this.config.page = page

            this.loadAsyncData()
        },
        downloadFile(id) {
            this.download({ formId: this.form.id, id: id }).then((fileText) => {
                this.toFile(fileText, '.env', 'text/plain')
            })
        },
        toFile(text, name, type) {
            var file = new Blob([text], { type: type })
            var a = document.createElement('a')
            a.href = URL.createObjectURL(file)
            a.download = name
            a.click()
        },
        deleteApp(app) {
            // this.loading = true
            this.appToDelete = app
            this.$refs.deleteApp.trigger()
        }
    }
}
</script>

<style scoped>
.text-decoration-line {
    text-decoration-line: line-through !important;
}
</style>
