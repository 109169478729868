<template>
    <div
        :id="item.id"
        :aria-labelledby="`${item.id}-tab`"
        role="tabpanel"
        class="tab-pane fade"
    >
        <ul
            class="is-flex is-justify-content-space-between is-align-items-center"
        >
            <li>
                <h3 class="text-lg leading-6 font-bold text-blue-500 py-6">
                    {{ item.name }}
                </h3>
            </li>
            <li>
                <b-button
                    tag="a"
                    :href="`/forms/${form.id}/app/create`"
                    class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Créer un déploiement
                </b-button>
            </li>
        </ul>
        <TabApplication :form="form"> </TabApplication>
    </div>
</template>

<script>
import FormMixin from './FormMixin'
import TabApplication from '../../Application/TabApplication'

export default {
    name: 'FormDeployments',
    mixins: [FormMixin],
    components: { TabApplication },
    props: ['item']
}
</script>
