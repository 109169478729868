<template>
    <div
        :id="item.id"
        class="tab-pane fade show active"
        role="tabpanel"
        :aria-labelledby="`${item.id}-tab`"
    >
        <h3
            id="applicant-information-title"
            class="text-lg leading-6 font-bold text-blue-500 py-6"
        >
            Statistiques
        </h3>
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <form-statistics-card
                label="Nombre d'étapes"
                :value="form.steps_count"
            />
            <form-statistics-card
                label="Nombre de champs"
                :value="form.attributes_count"
            />
            <form-statistics-card
                label="Nombre de réponses (tickets)"
                :value="form.posts_count"
            />
        </div>
        <h3
            id="applicant-information-title"
            class="text-lg leading-6 font-bold text-blue-500 py-6"
        >
            Liste des champs
        </h3>

      <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <section
                v-for="step in form.steps"
                :key="step.id"
            >
                <div class="bg-white shadow sm:rounded-lg" style="height: 100%">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 class="text-lg leading-6 font-medium text-blue-500">
                            {{ step.name }}
                        </h2>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <ul>
                            <li
                                class="mb-4 d-flex"
                                v-for="field in step.attributes"
                                :key="field.id"
                            >
                                <i
                                    class="fas fa-circle align-self-center mr-2"
                                    style="font-size: xx-small"
                                ></i>
                                <div>
                                    {{ field.label }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import FormMixin from './FormMixin'
import FormStatisticsCard from '@/components/Model/Form/FormComponents/FormStatisticsCard'

export default {
    name: 'FormInformation',
    components: { FormStatisticsCard },
    mixins: [FormMixin],
    props: ['item']
}
</script>
