<script>
import {mapActions, mapState} from "vuex";
export default {
    name: "FormMixin",
    computed: {
        ...mapState('form', {
            form: state => state.form,
        }),
    },
    created() {
        this.$store.commit('application/setFormId', this.form.id)
    },
    methods: {
        ...mapActions("form", []),
    },
}
</script>
